import Home from "./view/home/home";
import artistCategory from "./view/artistCategory/artistCategory";
import artist from "./view/artist/artist";
import RandomTeaser from "./view/randomTeaser/randomTeaser";
import RandomteaserPanel2 from "./view/randomTeaser/randomTeaserPanel2";
import Humanactivities from "./view/humanActivities/humanActivities";
// import TestPost from "./view/testPost/testPost";
import HumanPost from "./view/humanPost/humanPost";
import videoTemplate from "./view/videoTemplate/videoTemplate";
import constants from "./utils/appConstant";
import Main from "./view/main/main";

export default [
  {
    path: "/",
    exact: true,
    name: "Home",
    component: Main,
  },
  {
    path: "/c/:id",
    name: "artistCategory",
    component: artistCategory,
  },
  {
    path: "/" + constants.INTRO_URL,
    name: "random-teaser",
    exact: true,
    component: RandomTeaser,
  },
  {
    path: "/intro-panel2",
    name: "random-teaser-panel2",
    exact: true,
    component: RandomteaserPanel2,
  },
  {
    path: constants.INTRO_URL === "intro" ? "/gallery" : "/human-activities",
    name: "humanActivities",
    exact: true,
    component: Humanactivities,
  },
  {
    path: "/exhibition/:exhibitionId/:panelUrl",
    name: "humanActivitiesPanel1",
    exact: true,
    component: Humanactivities,
  },
  {
    path: "/panel1",
    name: "humanActivitiesPanel1",
    exact: true,
    component: Humanactivities,
  },
  {
    path: "/panel2",
    name: "humanActivitiesPanel2",
    exact: true,
    component: Humanactivities,
  },
  {
    path: "/panel3",
    name: "humanActivitiesPanel3",
    exact: true,
    component: Humanactivities,
  },
  {
    path: "/panel4",
    name: "humanActivitiesPanel4",
    exact: true,
    component: Humanactivities,
  },
  {
    path: "/panel5",
    name: "humanActivitiesPanel5",
    exact: true,
    component: Humanactivities,
  },
  {
    path: "/panel6",
    name: "humanActivitiesPanel6",
    exact: true,
    component: Humanactivities,
  },
  {
    path: "/panel7",
    name: "humanActivitiesPanel7",
    exact: true,
    component: Humanactivities,
  },
  {
    path: "/panel8",
    name: "humanActivitiesPanel8",
    exact: true,
    component: Humanactivities,
  },
  // {
  //   path: "/",
  //   name: "random-teaser",
  //   exact: true,
  //   component: RandomTeaser,
  // },
  {
    path: "/h/:id",
    name: "human-post",
    exact: true,
    component: HumanPost,
  },
  // {
  //   path: "/human-activities-test-post",
  //   name: 'human-activities-test-post',
  //   exact: true,
  //   component: TestPostOne,
  // },
  // {
  //   path: "/human-activities-test-post-1",
  //   name: 'human-activities-test-post-1',
  //   exact: true,
  //   component: TestPostOne,
  // },
  {
    path: "/video-template",
    name: "videoTemplate",
    exact: true,
    component: videoTemplate,
  },
];
