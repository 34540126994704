import React,{Component} from 'react';
import RandomTeaser from '../randomTeaser/randomTeaser';
import baadActions from "../../redux/actions/";
import { connect } from "react-redux";
import {Accordion,Button, Card} from 'react-bootstrap';
class Main extends Component{
   
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state={
         limit:30,
         offset:0,
         activeIndex:0
        }
    }
    
    async componentDidMount() {
        this.props.getExhibitionsListData(this.state.limit, this.state.offset);
    }
    
    render(){
    return (
        <>
       <Accordion defaultActiveKey="0">
        {
  this.props.exhibitionsListData.exhibitions.map((exhibition,i)=>(
    <Card >
    <Card.Header style={{paddingBottom:"10px", paddingTop:"10px"}}>
      <Accordion.Toggle as={Button} variant="link" eventKey={'ex'+i}>
       <h2>{exhibition.title}</h2> 
      </Accordion.Toggle>
      <span>starts: {exhibition.exhibitionStartDate? new Date(exhibition.exhibitionStartDate).toLocaleDateString():""}</span>
      <span style={{paddingLeft:"5px"}}>ends: {exhibition.exhibitionStartDate? new Date(exhibition.exhibitionEndDate).toLocaleDateString():""}</span>
      <div style={{float:"right", marginRight:"20px"}}><a href={`/exhibition/${exhibition?.id}${exhibition.exhibitionPublicUrl||exhibition?.children[0]?.exhibitionPublicUrl}`}>VIEW</a></div>
    </Card.Header>
    <Accordion.Collapse eventKey={'ex'+i}>
      <Card.Body>

      <img style={{width:"80%"}}
                src={exhibition?.exhibitionIntroImage[0]?.url}
                className="media-style"
                alt=""
              />
        </Card.Body>
    </Accordion.Collapse>
  </Card>
   
))
        }
        </Accordion>
  
      {/* <RandomTeaser  exhibitionId={624}/> */}
      </>
    )
    }
    
}
function mapStateToProps(state) {
    return {
      exhibitionsListData: state.exhibitionsListData
    };
  }
  
  const mapDispatchToProps = {
    getExhibitionsListData: baadActions.getExhibitionsListData
  };

export default connect(mapStateToProps, mapDispatchToProps)(Main)
