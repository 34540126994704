import baadActions from "../actions/index";
import constants from "../../utils/constants";
import { getRandomColor } from "../../components/CursorChat/helper";
import produce from "immer";
import { number } from "prop-types";

const initialState = {
  categories: [],
  categoryName: "",
  categoriesData: [],
  categoriesDataContent: [],
  postData: [],
  pageData: [],
  totalCount: [],
  humanData: [],
  frontUrl: "",
  mousePos: "",
  humanPostData: [],
  exhibitionsData: [],
  queendomData: [],
  mainData: [],
  exhibitionsListData: { exhibitions: [], total: 0 },
  panelData: [],
  contributionData: [],
  showChat: false,
  cursor: { x: 0, y: 0 },
  room: false,
  clientID: false,
  message: [],
  message_local: [],
  meData: [],
  presenceData: [],
  color: getRandomColor(),
  isTyping: false,
  showInput: false,
  userStatus: {
    isGuide: false,
    joinedGuide: false,
    myVideoUrl: "",
  },
  guideStatus: {
    guidePresent: false,
    guideUrl: false,
    guideRoom: false,
    //videoLoaded: false,
  },
  cursorActive: false,
};

export default function baadReducer(state = initialState, action) {
  switch (action.type) {
    case baadActions.SET_CURSOR_ACTIVE:
      return {
        ...state,
        cursorActive: action.payload,
      };

    case baadActions.SET_GUIDE_STATUS:
      return {
        ...state,
        guideStatus: action.payload,
      };

    case baadActions.SET_USER_STATUS:
      return {
        ...state,
        userStatus: action.payload,
      };

    case baadActions.SET_COLOR:
      return {
        ...state,
        color: action.payload,
      };

    case baadActions.SET_TYPING_INDICATOR:
      return {
        ...state,
        isTyping: action.payload,
      };

    case baadActions.SET_SHOW_INPUT:
      return {
        ...state,
        showInput: action.payload,
      };

    case baadActions.SET_CURSOR:
      return {
        ...state,
        cursor: action.payload,
      };

    case baadActions.SET_PRESENCE_DATA:
      return {
        ...state,
        presenceData: action.payload,
      };

    case baadActions.SET_ME_DATA:
      return {
        ...state,
        meData: action.payload,
      };

    case baadActions.SET_ROOM:
      return {
        ...state,
        room: action.payload,
      };

    case baadActions.SET_CLIENT_ID:
      return {
        ...state,
        clientID: action.payload,
      };

    case baadActions.SET_MESSAGE:
      return {
        ...state,
        message: [
          ...state.message,
          {
            text: action.payload,
            status: "active",
            ts: Date.now(),
            type: "message",
          },
        ],
      };

    case baadActions.HIDE_MESSAGE:
      const firstActiveMessageIndex = state?.message?.findIndex((message) => {
        return message.status === "active";
      });
      if (firstActiveMessageIndex !== -1) {
        return produce(state, (draftState) => {
          draftState.message[firstActiveMessageIndex] = {
            ...draftState.message[firstActiveMessageIndex],
            status: "hidden",
          };
        });
      } else {
        return {
          ...state,
        };
      }

    case baadActions.REMOVE_MESSAGE:
      return produce(state, (draftState) => {
        draftState.message = draftState.message?.slice(1);
      });

    case baadActions.SET_MESSAGE_LOCAL:
      return {
        ...state,
        message_local: [
          ...state.message_local,
          { text: action.payload, status: "active", ts: Date.now() },
        ],
      };

    case baadActions.ADD_MESSAGE_LOCAL:
      return {
        ...state,
        message_local: [
          ...state.message_local,
          { text: action.payload, status: "active", ts: Date.now() },
        ],
      };

    case baadActions.HIDE_MESSAGE_LOCAL:
      const firstActiveMessageLocalIndex = state?.message_local?.findIndex(
        (message) => {
          return message.status === "active";
        }
      );
      if (firstActiveMessageLocalIndex !== -1) {
        return produce(state, (draftState) => {
          draftState.message_local[firstActiveMessageLocalIndex] = {
            ...draftState.message_local[firstActiveMessageLocalIndex],
            status: "hidden",
          };
        });
      } else {
        return {
          ...state,
        };
      }

    case baadActions.HIDE_MESSAGE_LOCAL_ALL:
      return {
        ...state,
        message_local: state.message_local.map((message_local) => {
          return { ...message_local, status: "hidden" };
        }),
      };

    case baadActions.REMOVE_MESSAGE_LOCAL:
      return produce(state, (draftState) => {
        draftState.message_local = draftState.message_local?.slice(1);
      });

    case baadActions.REMOVE_MESSAGE_LOCAL_ALL: {
      return {
        ...state,
        message_local: [],
      };
    }

    case baadActions.REQUEST_FOR_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case baadActions.CATEGORIES_NAME:
      return {
        ...state,
        categoryName: action.name,
      };
    case baadActions.SEND_FRONT_URL:
      return {
        ...state,
        frontUrl: action.payload,
      };
    case baadActions.MOUSE_IN:
      return {
        ...state,
        mousePos: action.payload,
      };
    case baadActions.MOUSE_OUT:
      return {
        ...state,
        mousePos: action.payload,
      };
    case constants.CATEGORIES_DATA:
      return {
        ...state,
        categoriesDataContent: action.categoriesData,
      };
    case constants.POST_DATA:
      return {
        ...state,
        postData: action.postData,
      };
    case constants.HUMAN_POST_DATA:
      return {
        ...state,
        humanPostData: action.humanPostData,
      };

    case baadActions.TOTAL_LINK_COUNTER:
      return {
        ...state,
        totalCount: action.count,
      };
    case baadActions.SET_CATEGORIES_RESULT:
      return {
        ...state,
        categoriesData: action.categories,
      };
    case constants.PAGE_DATA:
      return {
        ...state,
        pageData: action.pageData,
      };
    case constants.HUMAN_DATA:
      return {
        ...state,
        humanData: action.humanData,
      };
    case constants.EXHIBITIONS_DATA:
      return {
        ...state,
        exhibitionsData: action.exhibitionsData,
      };
    case constants.QUEENDOM_DATA:
      return {
        ...state,
        queendomData: action.queendomData,
      };

    case constants.MAIN_DATA:
      return {
        ...state,
        mainData: action.mainData,
      };

    case constants.EXHIBITIONS_LIST_DATA:
      return {
        ...state,
        exhibitionsListData: action.exhibitionsListData,
      };

    case constants.PANEL_DATA:
      return {
        ...state,
        panelData: action.panelData,
      };
    case constants.CONTRIBUTION_DATA:
      return {
        ...state,
        contributionData: action.contributionData,
      };
    default:
      return state;
  }
}
