import {
  all,
  takeEvery,
  fork,
  put,
  setContext,
  call,
  getContext,
  delay,
} from "redux-saga/effects";
import { useSearchParams } from "react-router-dom";
import constants from "../../utils/constants";
import actions from "../actions/index";
import HttpProcessor from "../../utils/httpProcessor";

import appConstants from "../../utils/appConstant";
import { createGQLClient } from "../../utils/graphQLClient";

import { loader } from "graphql.macro";
import baadActions from "../actions/index";

const FETCH_EXHIBITIONS_LIST_QUERY = loader(
  "../../gql/fetch_exhibitions_query.graphql"
);
const MAIN_QUERY = loader("../../gql/main_query.graphql");
const PANEL_QUERY = loader("../../gql/panel_query.graphql");
const CONTRIBUTION_QUERY = loader("../../gql/contribution_query.graphql");

const ql_client = createGQLClient();

function* getExhibitionsListData() {
  yield takeEvery(actions.GET_EXHIBITIONS_LIST, function* (action) {
    const client = yield getContext("ql_client");
    const json = yield call(client.query, "", FETCH_EXHIBITIONS_LIST_QUERY, {
      limit: action.limit,
      offset: action.offset,
    });
    yield put({
      type: constants.EXHIBITIONS_LIST_DATA,
      exhibitionsListData: json,
    });
    console.log(json);
  });
}
function* getMainData() {
  yield takeEvery(actions.GET_MAIN_DATA, function* (action) {
    const client = yield getContext("ql_client");
    const json = yield call(client.query, "entry", MAIN_QUERY, {
      entry_id: action.exhibitionId || appConstants.CRAFT_EXHIBITION_ID,
    });
    yield put({ type: constants.MAIN_DATA, mainData: json });
  });
}

function* getRoomData() {
  yield takeEvery(actions.GET_ROOM_DATA, function* (action) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getRoomDataLiveblocks();
    yield put({ type: constants.GET_ROOM_DATA, roomData: json });
  });
}

function* getPanelData() {
  yield takeEvery(actions.GET_PANEL_DATA, function* (action) {
    const client = yield getContext("ql_client");
    const json = yield call(client.query, "entry", PANEL_QUERY, {
      public_url: action.publicUrl,
    });
    yield put({ type: constants.PANEL_DATA, panelData: json });
  });
}

function* getContributionData() {
  yield takeEvery(actions.GET_CONTRIBUTION_DATA, function* (action) {
    const client = yield getContext("ql_client");
    const json = yield call(client.query, "entry", CONTRIBUTION_QUERY, {
      slug: action.slug,
    });
    yield put({ type: constants.CONTRIBUTION_DATA, contributionData: json });
  });
}

function* requestForGetCategories() {
  // let httpProcessor = new HttpProcessor();
  // const json = yield httpProcessor.getCategories();
  // console.log("---requestForGetCategories---", json);
  // yield put({ type: actions.REQUEST_FOR_CATEGORIES, categories: json });

  yield takeEvery(actions.REQUEST_FOR_CATEGORIES, function* (action) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getCategories();
    yield put({ type: actions.SET_CATEGORIES_RESULT, categories: json });
  });
}

function* setCetogoriesData() {
  let httpProcessor = new HttpProcessor();
  const json = yield httpProcessor.getCategories();
  //console.log("---setCetogoriesData---", json);
  yield put({ type: actions.SET_CATEGORIES_RESULT, categories: json });
}

function* requestForGetCategoriesData() {
  yield takeEvery(actions.REQUEST_FOR_CATEGORIES_DATA, function* (action) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getCategoriesData(action.id, action.count);
    yield put({ type: constants.CATEGORIES_DATA, categoriesData: json });
  });
}

function* getPostData() {
  yield takeEvery(actions.GET_POST_DATA, function* (action) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getPost(action.postId);
    yield put({ type: constants.POST_DATA, postData: json });
  });
}
function* getPostDataBySlug() {
  yield takeEvery(actions.GET_POST_DATA_BYSLUG, function* (action, resolve) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getPostBySlug(action.slug);
    yield put({ type: constants.POST_DATA, postData: json[0] });
    if (action) {
      action.resolve("done");
    }
  });
}
function* getHumanPostDataBySlug() {
  yield takeEvery(
    actions.GET_HUMAN_POST_DATA_BYSLUG,
    function* (action, resolve) {
      let httpProcessor = new HttpProcessor();
      const json = yield httpProcessor.getPostBySlug(action.slug);
      yield put({ type: constants.HUMAN_POST_DATA, humanPostData: json[0] });
      if (action) {
        action.resolve("done");
      }
    }
  );
}
function* getPageDataBySlug() {
  yield takeEvery(actions.GET_PAGE_DATA_BYSLUG, function* (action, resolve) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getPageBySlug(action.slug);
    yield put({ type: constants.PAGE_DATA, pageData: json[0] });
    if (action) {
      action.resolve("done");
    }
  });
}
function* getHumanData() {
  yield takeEvery(actions.GET_HUMAN_DATA, function* (action) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getHumanData();
    yield put({ type: constants.HUMAN_DATA, humanData: json });
  });
}
function* getExhibitionsData() {
  yield takeEvery(actions.GET_EXHIBITIONS_DATA, function* (action) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getExhibitionsData();
    yield put({ type: constants.EXHIBITIONS_DATA, exhibitionsData: json });
  });
}
function* getQueendomData() {
  yield takeEvery(actions.GET_QUEENDOM_DATA, function* (action) {
    let httpProcessor = new HttpProcessor();
    const json = yield httpProcessor.getQueendomData();
    yield put({ type: constants.QUEENDOM_DATA, queendomData: json });
  });
}

function* setMessageSaga() {
  yield takeEvery(actions.SET_MESSAGE_SAGA, function* (action) {
    yield put({ type: baadActions.SET_MESSAGE, payload: action.message });
    yield delay(5000);
    yield put({ type: baadActions.HIDE_MESSAGE });
    //sync to CSS animation
    yield delay(500);
    yield put({ type: baadActions.REMOVE_MESSAGE });
  });
}

function* setMessageLocalSaga() {
  yield takeEvery(actions.SET_MESSAGE_LOCAL_SAGA, function* (action) {
    yield put({
      type: baadActions.SET_MESSAGE_LOCAL,
      payload: action.message_local,
    });
    yield delay(10000);
    yield put({ type: baadActions.HIDE_MESSAGE_LOCAL });
    //sync to CSS animation
    yield delay(500);
    yield put({ type: baadActions.REMOVE_MESSAGE_LOCAL });
  });
}

function* introLocalMessageSaga() {
  yield takeEvery(actions.START_INTRO_MESSAGE_LOCAL, function* (action) {
    yield delay(6000);
    yield put({ type: baadActions.ADD_MESSAGE_LOCAL, payload: "Hello!" });
    yield delay(2000);
    yield put({
      type: baadActions.ADD_MESSAGE_LOCAL,
      payload: "Right-Click to chat",
    });
    yield delay(1000);
    yield put({ type: baadActions.HIDE_MESSAGE_LOCAL });
    yield delay(500);
    yield put({ type: baadActions.REMOVE_MESSAGE_LOCAL });
    yield delay(3000);
    yield put({ type: baadActions.HIDE_MESSAGE_LOCAL });
    yield delay(500);
    yield put({ type: baadActions.REMOVE_MESSAGE_LOCAL });
  });
}

function* clearMessageLocalSaga() {
  yield takeEvery(actions.CLEAR_MESSAGE_LOCAL, function* (action) {
    yield put({ type: baadActions.HIDE_MESSAGE_LOCAL_ALL });
    yield delay(500);
    yield put({ type: baadActions.REMOVE_MESSAGE_LOCAL_ALL });
  });
}

function* messageIndicatorSaga() {
  yield takeEvery(actions.SET_TYPING_INDICATOR, function* (action) {
    yield put({ type: baadActions.SHOW_TYPING_INDICATOR });
    yield delay(500);
    yield put({ type: baadActions.HIDE_TYPING_INDICATOR });
  });
}

export default function* rootSaga() {
  yield setContext({ ql_client });
  yield all([
    // fork(watchGetCategories),
    fork(requestForGetCategories),
    //fork(setCetogoriesData),
    fork(requestForGetCategoriesData),
    fork(getPostData),
    fork(getPostDataBySlug),
    fork(getHumanPostDataBySlug),
    fork(getPageDataBySlug),
    fork(getHumanData),
    fork(getExhibitionsData),
    fork(getQueendomData),
    fork(getMainData),
    fork(getExhibitionsListData),
    fork(getPanelData),
    fork(getContributionData),
    fork(getRoomData),
    fork(setMessageSaga),
    fork(setMessageLocalSaga),
    fork(introLocalMessageSaga),
    fork(clearMessageLocalSaga),
  ]);
}
