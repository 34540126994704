const baadActions = {
  REQUEST_FOR_CATEGORIES: "REQUEST_FOR_CATEGORIES",
  CATEGORIES_NAME: "CATEGORIES_NAME",
  REQUEST_FOR_CATEGORIES_DATA: "REQUEST_FOR_CATEGORIES_DATA",
  GET_POST_DATA: "GET_POST_DATA",
  GET_POST_DATA_BYSLUG: "GET_POST_DATA_BYSLUG",
  GET_HUMAN_POST_DATA_BYSLUG: "GET_HUMAN_POST_DATA_BYSLUG",
  SET_CATEGORIES_DATA: "SET_CATEGORIES_DATA",
  TOTAL_LINK_COUNTER: "TOTAL_LINK_COUNTER",
  SET_CATEGORIES_RESULT: "SET_CATEGORIES_RESULT",
  GET_PAGE_DATA_BYSLUG: "GET_PAGE_DATA_BYSLUG",
  GET_HUMAN_DATA: "GET_HUMAN_DATA",
  SEND_FRONT_URL: "SEND_FRONT_URL",
  MOUSE_IN: "MOUSE_IN",
  MOUSE_OUT: "MOUSE_OUT",
  GET_EXHIBITIONS_DATA: "GET_EXHIBITIONS_DATA",
  GET_QUEENDOM_DATA: "GET_QUEENDOM_DATA",
  GET_MAIN_DATA: "GET_MAIN_DATA",
  GET_ROOM_DATA: "GET_ROOM_DATA",
  GET_PANEL_DATA: "GET_PANEL_DATA",
  GET_CONTRIBUTION_DATA: "GET_CONTRIBUTION_DATA",
  SET_CURSOR: "SET_CURSOR",
  SET_ROOM: "SET_ROOM",
  SET_CLIENT_ID: "SET_CLIENT_ID",
  SET_MESSAGE: "SET_MESSAGE",
  SET_MESSAGE_SAGA: "SET_MESSAGE_SAGA",
  REMOVE_MESSAGE: "REMOVE_MESSAGE",
  SET_MESSAGE_LOCAL: "SET_MESSAGE_LOCAL",
  SET_MESSAGE_LOCAL_SAGA: "SET_MESSAGE_LOCAL_SAGA",
  ADD_MESSAGE_LOCAL: "ADD_MESSAGE_LOCAL",
  REMOVE_MESSAGE_LOCAL: "REMOVE_MESSAGE_LOCAL",
  REMOVE_MESSAGE_LOCAL_ALL: "REMOVE_MESSAGE_LOCAL_ALL",
  START_INTRO_MESSAGE_LOCAL: "START_INTRO_MESSAGE_LOCAL",
  CLEAR_MESSAGE_LOCAL: "CLEAR_MESSAGE_LOCAL",
  SET_PRESENCE_DATA: "SET_PRESENCE_DATA",
  SET_ME_DATA: "SET_ME_DATA",
  HIDE_MESSAGE: "HIDE_MESSAGE",
  HIDE_MESSAGE_LOCAL: "HIDE_MESSAGE_LOCAL",
  HIDE_MESSAGE_LOCAL_ALL: "HIDE_MESSAGE_LOCAL_ALL",
  SET_TYPING_INDICATOR: "SET_TYPING_INDICATOR",
  SET_SHOW_INPUT: "SET_SHOW_INPUT",
  SET_COLOR: "SET_COLOR",
  SET_USER_STATUS: "SET_USER_STATUS",
  SET_GUIDE_STATUS: "SET_GUIDE_STATUS",
  SET_CURSOR_ACTIVE: "SET_CURSOR_ACTIVE",
  GET_EXHIBITIONS_LIST: "GET_EXHIBITIONS_LIST",

  setCursorActive: (data) => ({
    type: baadActions.SET_CURSOR_ACTIVE,
    payload: data,
  }),

  setGuideStatus: (data) => ({
    type: baadActions.SET_GUIDE_STATUS,
    payload: data,
  }),

  setUserStatus: (data) => ({
    type: baadActions.SET_USER_STATUS,
    payload: data,
  }),

  setColor: (data) => ({
    type: baadActions.SET_COLOR,
    payload: data,
  }),

  setTypingIndicator: (data) => ({
    type: baadActions.SET_TYPING_INDICATOR,
    payload: data,
  }),

  setShowInput: (data) => ({
    type: baadActions.SET_SHOW_INPUT,
    payload: data,
  }),

  setPresenceData: (data) => ({
    type: baadActions.SET_PRESENCE_DATA,
    payload: data,
  }),

  setMeData: (data) => ({
    type: baadActions.SET_ME_DATA,
    payload: data,
  }),

  setMessage: (data) => ({
    type: baadActions.SET_MESSAGE,
    payload: data,
  }),

  setMessageLocal: (data) => ({
    type: baadActions.SET_MESSAGE_LOCAL,
    payload: data,
  }),

  setClientID: (data) => ({
    type: baadActions.SET_CLIENT_ID,
    payload: data,
  }),

  setRoom: (data) => ({
    type: baadActions.SET_ROOM,
    payload: data,
  }),

  setCursor: (data) => ({
    type: baadActions.SET_CURSOR,
    payload: data,
  }),

  requestForGetCategories: () => ({
    type: baadActions.REQUEST_FOR_CATEGORIES,
  }),
  categoriesName: (name) => ({
    type: baadActions.CATEGORIES_NAME,
    name,
  }),
  setCetogoriesData: (categories) => ({
    type: baadActions.SET_CATEGORIES_RESULT,
    categories,
  }),
  requestForGetCategoriesData: (id, count) => ({
    type: baadActions.REQUEST_FOR_CATEGORIES_DATA,
    id,
    count,
  }),
  getPostData: (postId) => ({
    type: baadActions.GET_POST_DATA,
    postId,
  }),
  getPostDataBySlug: (slug, resolve) => ({
    type: baadActions.GET_POST_DATA_BYSLUG,
    slug,
    resolve,
  }),
  getHumanPostDataBySlug: (slug, resolve) => ({
    type: baadActions.GET_HUMAN_POST_DATA_BYSLUG,
    slug,
    resolve,
  }),
  getPageDataBySlug: (slug, resolve) => ({
    type: baadActions.GET_PAGE_DATA_BYSLUG,
    slug,
    resolve,
  }),
  getHumanData: () => ({
    type: baadActions.GET_HUMAN_DATA,
  }),
  sendFrontUrl: (data) => ({
    type: baadActions.SEND_FRONT_URL,
    payload: data,
  }),
  mousePosIn: () => ({
    type: baadActions.MOUSE_IN,
    payload: "in",
  }),
  mousePosOut: () => ({
    type: baadActions.MOUSE_OUT,
    payload: "out",
  }),
  totalLinkCounter: (count) => ({
    type: baadActions.TOTAL_LINK_COUNTER,
    count,
  }),
  getExhibitionsData: () => ({
    type: baadActions.GET_EXHIBITIONS_DATA,
  }),
  getQueendomData: () => ({
    type: baadActions.GET_QUEENDOM_DATA,
  }),
  getExhibitionsListData: (limit, offset) => ({
    type: baadActions.GET_EXHIBITIONS_LIST,
    limit: limit,
    offset: offset,
  }),
  getMainData: (exhibitionId) => ({
    type: baadActions.GET_MAIN_DATA,
    exhibitionId,
  }),
  getRoomData: () => ({
    type: baadActions.GET_ROOM_DATA,
  }),
  getPanelData: (publicUrl) => ({
    type: baadActions.GET_PANEL_DATA,
    publicUrl,
  }),
  getContributionData: (slug) => ({
    type: baadActions.GET_CONTRIBUTION_DATA,
    slug,
  }),
  setMessageSaga: (message) => ({
    type: baadActions.SET_MESSAGE_SAGA,
    message,
  }),
  setMessageLocalSaga: (message_local) => ({
    type: baadActions.SET_MESSAGE_LOCAL_SAGA,
    message_local,
  }),
  startIntroMessageLocalSaga: () => ({
    type: baadActions.START_INTRO_MESSAGE_LOCAL,
  }),
  clearMessageLocalSaga: () => ({
    type: baadActions.CLEAR_MESSAGE_LOCAL,
  }),
};

export default baadActions;
